import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import axios from "axios";
import TextField from "@material-ui/core/TextField";

const FlexWrapper = styled.article`
  ${tw`flex flex-col px-4 py-8 sm:p-8 mb-8 bg-black-700`}
`;

const Content = styled.div`
  ${tw`flex flex-col sm:flex-row`}
`;

const ContentWrapper = styled.div`
  ${tw`flex-1`}
`;

const Title = styled.h2`
  ${tw`w-full leading-normal text-4xl my-0 font-semibold mb-6`}
`;

const InputWrapper = styled.div`
  ${tw`w-full`}

  & div {
    ${tw`w-full`}
  }
`;

const InputRow = styled.div`
  ${tw`flex justify-between flex-col sm:flex-row w-full mt-8`}

  & > div {
    ${tw`w-full mb-8 sm:w-input sm:mb-0`}

    &:last-of-type {
      ${tw`mb-0`}
    }
  }
`;

const HiddenField = styled.div`
  ${tw`hidden`}
`;

const DescFieldWrapper = styled.div`
  ${(props) => (props.isVisible === true ? tw`mb-8` : tw`hidden`)}

  & div {
    ${tw`w-full`}
  }
`;

const RespondReminder = styled.div`
  ${tw`my-4 text-center text-black-300`}
`;

function ContactProfile({ artist }) {
  const [visible, setVisible] = useState(false);
  const { control, handleSubmit } = useForm();

  const toggleField = () => {
    setVisible(!visible);
  };

  const onSubmit = (data) => {
    axios
      .post("https://formcarry.com/s/GXr0KacAusQ", data, {
        headers: { Accept: "application/json" },
      })
      .then((response) => {
        if (response.status === 200) {
          window.location.href = "https://djbooking.dk/besked-modtaget-artist/";
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <FlexWrapper id="artist-form">
      <Title>
        <span style={tw`text-primary`}>Forespørg på</span> {artist}
      </Title>
      <Content>
        <ContentWrapper>
          <form onSubmit={handleSubmit(onSubmit)} acceptCharset="UTF-8">
            <HiddenField>
              <Controller
                as={<TextField />}
                type="text"
                name="artist"
                control={control}
                defaultValue={artist}
                InputProps={{
                  readOnly: true,
                }}
                label="Artist"
                variant="outlined"
                style={{ display: "none" }}
              />
            </HiddenField>
            <InputWrapper>
              <Controller
                as={<TextField />}
                required
                type="text"
                name="navn"
                control={control}
                label="Navn eller firma"
                variant="outlined"
              />
            </InputWrapper>
            <InputRow>
              <Controller
                as={<TextField />}
                required
                type="email"
                name="email"
                control={control}
                label="E-mail"
                variant="outlined"
              />
              <Controller
                as={<TextField />}
                required
                type="tel"
                name="telefon"
                control={control}
                label="Telefon"
                variant="outlined"
              />
            </InputRow>
            <p
              style={{ marginTop: "2rem", marginBottom: "2rem" }}
              onClick={toggleField}
            >
              {visible ? "-" : "+"} Tilføj en bemærkning (Valgfri)
            </p>
            <DescFieldWrapper isVisible={visible}>
              <Controller
                as={<TextField />}
                multiline
                rows="4"
                name="beskrivelse"
                control={control}
                label="Beskrivelse af arrangement"
                placeholder="Evt. en kort beskrivelse af dit arrangement, dato, sted m.m..."
                variant="outlined"
              />
            </DescFieldWrapper>
            <input type="hidden" name="_gotcha"></input>
            <button
              type="submit"
              className="btn btn__large btn__primary u__full btn__pulse"
            >
              Send forespørgsel
            </button>
            <RespondReminder>
              Vi svarer på hverdage indenfor 24 timer
            </RespondReminder>
          </form>
        </ContentWrapper>
      </Content>
    </FlexWrapper>
  );
}

export default ContactProfile;
