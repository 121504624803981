import React, { Component } from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import ReactPlayer from "react-player";

const PlayerWrapper = styled.div`
  ${tw`relative overflow-hidden`}
  padding-top: 56.25%;
`;

const StyledReactPlayer = styled(props => <ReactPlayer {...props} />)`
  ${tw`w-full h-full absolute top-0 left-0 border-0`}
`;

export default class SoundCloud extends Component {
  render() {
    return (
      <PlayerWrapper>
        <StyledReactPlayer
          url={this.props.url}
          config={{
            soundcloud: {
              options: {
                visual: false,
                show_artwork: true,
                show_user: true,
                hide_related: true,
                sharing: true
              }
            }
          }}
          width="100%"
          height="100%"
          controls={true}
          volume={0.5}
        />
      </PlayerWrapper>
    );
  }
}
