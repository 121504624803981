import React from "react";
import SpotifyPlayer from "react-spotify-player";

export default class Spotify extends React.Component {
  render() {
    const size = {
      width: "100%",
      height: 400
    };
    const view = "coverart"; // or 'list'
    const theme = "black"; // or 'white'

    return (
      <div>
        <SpotifyPlayer
          uri={this.props.url}
          size={size}
          view={view}
          theme={theme}
        />
      </div>
    );
  }
}
