import React from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import NielsPicture from "../../images/xe-niels.png";
import businessData from "./business";

const FlexWrapper = styled.article`
  ${tw`flex items-start px-4 py-8 sm:p-8 bg-black-700`}
`;

const ImageContainer = styled.div`
  ${tw`w-2/12`}
`;

const Image = styled.img`
  ${tw`rounded-full h-full`}
`;

const Content = styled.div`
  ${tw`w-10/12 pl-8`}
`;

const Title = styled.p`
  ${tw`text-2xl mt-0 mb-2 font-semibold`}
`;

const Body = styled.p`
  ${tw`text-sm mt-0`}
`;

const ContactWrapper = styled.div`
  ${tw`flex flex-col sm:flex-row`}
`;

const ContactCTA = styled.a`
  ${tw`text-primary`}
  width: 48%;
  ${tw`w-full`}

  &:last-of-type {
    ${tw`mb-0`}
  }

  & svg {
    ${tw`mr-2`}
  }
`;

function ContactUsInfo() {
  const { telephone, email } = businessData;

  return (
    <FlexWrapper>
      <ImageContainer>
        <Image src={NielsPicture} />
      </ImageContainer>
      <Content>
        <Title>Har du spørgsmål?</Title>
        <Body>Ring nu, og vi vil gøre alt for at hjælpe dig...</Body>
        <ContactWrapper>
          <ContactCTA href={`tel:${telephone}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 32 32"
            >
              <title>phone-call</title>
              <link
                rel="preload"
                href="https://fonts.googleapis.com/css?family=Oswald%3A400%2C500%2C700%7COpen%20Sans%3A300%2C300italic%2Cregular%2Citalic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic&amp;display=swap"
                as="style"
              ></link>
              <g
                strokeLinecap="square"
                strokeLinejoin="miter"
                strokeWidth="2"
                fill="#ffe52b"
                stroke="#ffe52b"
              >
                <path
                  data-color="color-2"
                  d="M18,2A12,12,0,0,1,30,14"
                  fill="none"
                  strokeMiterlimit="10"
                ></path>{" "}
                <path
                  data-color="color-2"
                  d="M24,14a6,6,0,0,0-6-6"
                  fill="none"
                  strokeMiterlimit="10"
                ></path>{" "}
                <path
                  d="M20.693,19.293l-2.282,2.853a24.077,24.077,0,0,1-8.557-8.557l2.853-2.282a1.939,1.939,0,0,0,.56-2.3l-2.6-5.855A1.939,1.939,0,0,0,8.407,2.062L3.457,3.346A1.95,1.95,0,0,0,2.018,5.5,28.761,28.761,0,0,0,26.5,29.982a1.95,1.95,0,0,0,2.153-1.439l1.284-4.95a1.939,1.939,0,0,0-1.089-2.258l-5.855-2.6A1.939,1.939,0,0,0,20.693,19.293Z"
                  fill="none"
                  stroke="#ffe52b"
                  strokeMiterlimit="10"
                ></path>
              </g>
            </svg>
            +45 7021 7025
          </ContactCTA>
        </ContactWrapper>
      </Content>
    </FlexWrapper>
  );
}

export default ContactUsInfo;
