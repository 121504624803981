import React from "react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import RenderRating from "./RenderRating";

const Container = styled.div`
  ${tw`py-10 px-8 sm:p-12 mb-10 bg-black-700 rounded`}
`;

const Rating = styled.div`
  ${tw`flex flex-row items-center py-1 mb-8`}
`;

const RatingContainer = styled.div`
  ${tw`ml-4 flex flex-row`}
`;

const Person = styled.div`
  ${tw`mt-8`}
`;

const Name = styled.div`
  ${tw`font-bold`}
`;

function Testimonials({ data }) {
  return (
    <React.Fragment>
      {data.map(({ node }) => (
        <Container key={node.recommendationCreation.customerName}>
          <Rating>
            <span>Bedømmelse:</span>
            <RatingContainer>
              <RenderRating
                score={node.recommendationCreation.customerRating}
              />
            </RatingContainer>
          </Rating>
          {node.recommendationCreation.customerReview}
          <Person>
            <Name>{node.recommendationCreation.customerName}</Name>
            <span>{node.recommendationCreation.customerCompany}</span>
          </Person>
        </Container>
      ))}
    </React.Fragment>
  );
}

export default function CustomerTestimonials({ testimonials }) {
  return (
    <React.Fragment>
      {testimonials.length > 0 ? (
        <Testimonials data={testimonials} />
      ) : (
        <div></div>
      )}
    </React.Fragment>
  );
}

export const CustomerTestimonialsQuery = graphql`
  fragment CustomerTestimonialsFragment on WP_Recommendation {
    recommendationCreation {
      customerCompany
      customerName
      customerRating
      customerReview
    }
  }
`;
