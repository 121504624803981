import React from "react";
import YouTubePlayer from "./Youtube";
import SoundCloudPlayer from "./SoundCloud";
import SpotifyPlayer from "./Spotify";

function OutputPlayer({ type, url }) {
  if (type !== "tom" || type !== null) {
    if (type === "youtube") {
      return <YouTubePlayer url={url} />;
    } else if (type === "spotify") {
      return <SpotifyPlayer url={url} />;
    } else {
      return <SoundCloudPlayer url={url} />;
    }
  } else {
    return <div />;
  }
}

export default function PlayerFormat({ formatType, srcUrl }) {
  return (
    <div className="g__row player">
      <div className="g__c12">
        <OutputPlayer type={formatType} url={srcUrl} />
      </div>
    </div>
  );
}
